import * as React from "react";
import { JSX } from "react/jsx-runtime";

/**
 @name Unit Mhz
 @author yeyus
 @license MIT
 @description Mhz icon
*/
function UnitMhzIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 22" fill="none" role="img" aria-labelledby="megaHertzIcon" {...props}>
      <title id="megaHertzIcon">megahertz</title>
      <path
        d="M5.44 17L3.52 9.112H3.456C3.488 9.41067 3.50933 9.73067 3.52 10.072C3.54133 10.4027 3.55733 10.7333 3.568 11.064C3.58933 11.384 3.6 11.6933 3.6 11.992V17H0.944V5.576H5.088L6.64 12.328H6.672L8.208 5.576H12.32V17H9.6V11.944C9.6 11.6347 9.6 11.32 9.6 11C9.61067 10.68 9.62667 10.36 9.648 10.04C9.66933 9.70933 9.68533 9.4 9.696 9.112H9.648L7.744 17H5.44Z"
        fill="currentColor"
      />
      <path
        d="M15.688 9.44C15.688 9.776 15.676 10.092 15.652 10.388C15.628 10.676 15.596 10.94 15.556 11.18H15.664C15.76 10.964 15.876 10.788 16.012 10.652C16.156 10.508 16.32 10.404 16.504 10.34C16.696 10.276 16.908 10.244 17.14 10.244C17.524 10.244 17.86 10.336 18.148 10.52C18.436 10.704 18.66 10.976 18.82 11.336C18.98 11.696 19.06 12.144 19.06 12.68V17H16.948V13.328C16.948 12.88 16.904 12.548 16.816 12.332C16.736 12.108 16.608 11.996 16.432 11.996C16.232 11.996 16.076 12.068 15.964 12.212C15.86 12.356 15.788 12.58 15.748 12.884C15.708 13.18 15.688 13.552 15.688 14V17H13.6V7.88H15.688V9.44ZM24.4568 17H19.8368V15.788L21.9968 12.02H19.9568V10.364H24.3608V11.684L22.2968 15.344H24.4568V17Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default UnitMhzIcon;
