// @generated by protoc-gen-es v1.7.2 with parameter "target=ts"
// @generated from file cps/model/v1/channel.proto (package cps.model.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { UVK5ChannelCustomParams } from "./custom/uvk5/uvk5_pb.ts";

/**
 * @generated from enum cps.model.v1.Mode
 */
export enum Mode {
  /**
   * @generated from enum value: FM = 0;
   */
  FM = 0,

  /**
   * @generated from enum value: NFM = 1;
   */
  NFM = 1,

  /**
   * @generated from enum value: WFM = 2;
   */
  WFM = 2,

  /**
   * @generated from enum value: AM = 3;
   */
  AM = 3,

  /**
   * @generated from enum value: SSB = 4;
   */
  SSB = 4,

  /**
   * @generated from enum value: USB = 5;
   */
  USB = 5,

  /**
   * @generated from enum value: LSB = 6;
   */
  LSB = 6,

  /**
   * @generated from enum value: DMR = 7;
   */
  DMR = 7,

  /**
   * @generated from enum value: P25 = 8;
   */
  P25 = 8,

  /**
   * @generated from enum value: DSTAR = 9;
   */
  DSTAR = 9,

  /**
   * @generated from enum value: C4FM = 10;
   */
  C4FM = 10,

  /**
   * @generated from enum value: M17 = 11;
   */
  M17 = 11,

  /**
   * @generated from enum value: NXDN = 12;
   */
  NXDN = 12,
}
// Retrieve enum metadata with: proto3.getEnumType(Mode)
proto3.util.setEnumType(Mode, "cps.model.v1.Mode", [
  { no: 0, name: "FM" },
  { no: 1, name: "NFM" },
  { no: 2, name: "WFM" },
  { no: 3, name: "AM" },
  { no: 4, name: "SSB" },
  { no: 5, name: "USB" },
  { no: 6, name: "LSB" },
  { no: 7, name: "DMR" },
  { no: 8, name: "P25" },
  { no: 9, name: "DSTAR" },
  { no: 10, name: "C4FM" },
  { no: 11, name: "M17" },
  { no: 12, name: "NXDN" },
]);

/**
 * @generated from enum cps.model.v1.ChannelSlotType
 */
export enum ChannelSlotType {
  /**
   * @generated from enum value: MEMORY = 0;
   */
  MEMORY = 0,

  /**
   * @generated from enum value: VFO = 1;
   */
  VFO = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ChannelSlotType)
proto3.util.setEnumType(ChannelSlotType, "cps.model.v1.ChannelSlotType", [
  { no: 0, name: "MEMORY" },
  { no: 1, name: "VFO" },
]);

/**
 * @generated from message cps.model.v1.ToneSquelch
 */
export class ToneSquelch extends Message<ToneSquelch> {
  /**
   * @generated from field: optional fixed32 ctcss = 1;
   */
  ctcss?: number;

  /**
   * @generated from field: optional fixed32 dcs = 2;
   */
  dcs?: number;

  /**
   * @generated from field: optional bool dcs_reverse_polarity = 3;
   */
  dcsReversePolarity?: boolean;

  constructor(data?: PartialMessage<ToneSquelch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "cps.model.v1.ToneSquelch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ctcss", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
    { no: 2, name: "dcs", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
    { no: 3, name: "dcs_reverse_polarity", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ToneSquelch {
    return new ToneSquelch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ToneSquelch {
    return new ToneSquelch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ToneSquelch {
    return new ToneSquelch().fromJsonString(jsonString, options);
  }

  static equals(a: ToneSquelch | PlainMessage<ToneSquelch> | undefined, b: ToneSquelch | PlainMessage<ToneSquelch> | undefined): boolean {
    return proto3.util.equals(ToneSquelch, a, b);
  }
}

/**
 * @generated from message cps.model.v1.Power
 */
export class Power extends Message<Power> {
  /**
   * @generated from field: optional uint32 milliwatts = 1;
   */
  milliwatts?: number;

  /**
   * @generated from field: optional string label = 2;
   */
  label?: string;

  constructor(data?: PartialMessage<Power>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "cps.model.v1.Power";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "milliwatts", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Power {
    return new Power().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Power {
    return new Power().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Power {
    return new Power().fromJsonString(jsonString, options);
  }

  static equals(a: Power | PlainMessage<Power> | undefined, b: Power | PlainMessage<Power> | undefined): boolean {
    return proto3.util.equals(Power, a, b);
  }
}

/**
 * @generated from message cps.model.v1.ChannelSlot
 */
export class ChannelSlot extends Message<ChannelSlot> {
  /**
   * @generated from field: bool is_empty = 1;
   */
  isEmpty = false;

  /**
   * @generated from field: optional cps.model.v1.ChannelSlotType type = 2;
   */
  type?: ChannelSlotType;

  /**
   * @generated from field: optional cps.model.v1.Channel channel = 3;
   */
  channel?: Channel;

  constructor(data?: PartialMessage<ChannelSlot>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "cps.model.v1.ChannelSlot";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_empty", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ChannelSlotType), opt: true },
    { no: 3, name: "channel", kind: "message", T: Channel, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelSlot {
    return new ChannelSlot().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelSlot {
    return new ChannelSlot().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelSlot {
    return new ChannelSlot().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelSlot | PlainMessage<ChannelSlot> | undefined, b: ChannelSlot | PlainMessage<ChannelSlot> | undefined): boolean {
    return proto3.util.equals(ChannelSlot, a, b);
  }
}

/**
 * @generated from message cps.model.v1.Channel
 */
export class Channel extends Message<Channel> {
  /**
   * Frequencies are encoded in hertz
   *
   * @generated from field: fixed64 frequency = 2;
   */
  frequency = protoInt64.zero;

  /**
   * @generated from field: optional sint64 offset = 3;
   */
  offset?: bigint;

  /**
   * @generated from field: optional fixed64 step = 4;
   */
  step?: bigint;

  /**
   * @generated from field: optional fixed32 bandwidth = 5;
   */
  bandwidth?: number;

  /**
   * Squelch
   *
   * @generated from field: optional cps.model.v1.ToneSquelch rx_tone = 10;
   */
  rxTone?: ToneSquelch;

  /**
   * @generated from field: optional cps.model.v1.ToneSquelch tx_tone = 11;
   */
  txTone?: ToneSquelch;

  /**
   * @generated from field: optional cps.model.v1.Mode mode = 20;
   */
  mode?: Mode;

  /**
   * @generated from field: optional string name = 40;
   */
  name?: string;

  /**
   * @generated from field: optional cps.model.v1.Power power = 60;
   */
  power?: Power;

  /**
   * Custom radio section 
   *
   * @generated from field: optional cps.model.v1.custom.uvk5.UVK5ChannelCustomParams uvk5_custom_channel_params = 1000;
   */
  uvk5CustomChannelParams?: UVK5ChannelCustomParams;

  /**
   * metadata section 
   *
   * @generated from field: optional string comment = 100;
   */
  comment?: string;

  /**
   * links this memory to an upstream entity like a repeater definition, PMR channel, etc.
   *
   * @generated from field: optional string parent_entity = 103;
   */
  parentEntity?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp created_at = 101;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp updated_at = 102;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Channel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "cps.model.v1.Channel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "frequency", kind: "scalar", T: 6 /* ScalarType.FIXED64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 18 /* ScalarType.SINT64 */, opt: true },
    { no: 4, name: "step", kind: "scalar", T: 6 /* ScalarType.FIXED64 */, opt: true },
    { no: 5, name: "bandwidth", kind: "scalar", T: 7 /* ScalarType.FIXED32 */, opt: true },
    { no: 10, name: "rx_tone", kind: "message", T: ToneSquelch, opt: true },
    { no: 11, name: "tx_tone", kind: "message", T: ToneSquelch, opt: true },
    { no: 20, name: "mode", kind: "enum", T: proto3.getEnumType(Mode), opt: true },
    { no: 40, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 60, name: "power", kind: "message", T: Power, opt: true },
    { no: 1000, name: "uvk5_custom_channel_params", kind: "message", T: UVK5ChannelCustomParams, opt: true },
    { no: 100, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 103, name: "parent_entity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 101, name: "created_at", kind: "message", T: Timestamp, opt: true },
    { no: 102, name: "updated_at", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Channel {
    return new Channel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Channel {
    return new Channel().fromJsonString(jsonString, options);
  }

  static equals(a: Channel | PlainMessage<Channel> | undefined, b: Channel | PlainMessage<Channel> | undefined): boolean {
    return proto3.util.equals(Channel, a, b);
  }
}

