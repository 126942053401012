import * as React from "react";
import { JSX } from "react/jsx-runtime";

/**
 @name Slot MR
 @author yeyus
 @license MIT
 @description MR Slot
*/
function SlotMRIcon(props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C10.1243 2 8.54878 2.0992 7.25007 2.38782C5.94002 2.67897 4.85116 3.176 4.01358 4.01358C3.176 4.85116 2.67897 5.94002 2.38782 7.25007C2.0992 8.54878 2 10.1243 2 12C2 13.8757 2.0992 15.4512 2.38782 16.7499C2.67897 18.06 3.176 19.1488 4.01358 19.9864C4.85116 20.824 5.94002 21.321 7.25007 21.6122C8.54878 21.9008 10.1243 22 12 22C13.8757 22 15.4512 21.9008 16.7499 21.6122C18.06 21.321 19.1488 20.824 19.9864 19.9864C20.824 19.1488 21.321 18.06 21.6122 16.7499C21.9008 15.4512 22 13.8757 22 12C22 10.1243 21.9008 8.54878 21.6122 7.25007C21.321 5.94002 20.824 4.85116 19.9864 4.01358C19.1488 3.176 18.06 2.67897 16.7499 2.38782C15.4512 2.0992 13.8757 2 12 2ZM7.2 11.07L8.4 16H9.84L11.03 11.07H11.06C11.0533 11.25 11.0433 11.4433 11.03 11.65C11.0167 11.85 11.0067 12.05 11 12.25V12.84V16H12.7V8.86H10.13L9.17 13.08H9.15L8.18 8.86H5.59V16H7.25V12.87C7.25 12.6833 7.24333 12.49 7.23 12.29C7.22333 12.0833 7.21333 11.8767 7.2 11.67C7.19333 11.4567 7.18 11.2567 7.16 11.07H7.2ZM17.321 9.1C16.9477 8.94 16.4777 8.86 15.911 8.86H13.881V16H15.691V13.34H15.901L17.051 16H19.061L17.461 12.89C17.681 12.7433 17.861 12.5767 18.001 12.39C18.141 12.1967 18.2477 11.9833 18.321 11.75C18.3943 11.51 18.431 11.25 18.431 10.97C18.431 10.4967 18.3377 10.1067 18.151 9.8C17.971 9.48667 17.6943 9.25333 17.321 9.1ZM15.691 10.29H15.911C16.0643 10.29 16.191 10.32 16.291 10.38C16.391 10.44 16.4643 10.5267 16.511 10.64C16.5643 10.7533 16.591 10.8933 16.591 11.06C16.591 11.2733 16.561 11.45 16.501 11.59C16.4477 11.7233 16.3643 11.8233 16.251 11.89C16.1443 11.95 16.021 11.98 15.881 11.98H15.691V10.29Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SlotMRIcon;
